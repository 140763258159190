/* --------------------------------------------------
  Typography styling
-------------------------------------------------- */

body {
  font-family: $primary-font;
}

a {
  color: $primary-green;
  font-size: 16px;
  line-height: 19px;

  &:hover {
    color: $dark-green;
  }
  &:focus {
    color: $dark-green;
  }

  &.bold {
    font-weight: 700;
  }

  &.border-btm {
    border-bottom: 2px solid $primary-green;
  }
}

p {
  color: $black-light;
  font-size: 20px;
  line-height: 27px;
}

h2 {
  color: $black;
  font-size: 32px;
  line-height: 43px;
  font-weight: bold;
}

h3 {
  color: $black;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
}

.tagline {
  color: $black-light;
  font-size: 20px;
  line-height: 27px;

  span {
    font-weight: 600;
  }
}

// custom title css
.title-main {
  color: $black;
  font-size: 32px;
  line-height: 43px;
  font-weight: bold;
  // margin-bottom: 50px;

  @media (max-width: 767px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  span {
    color: $black-light;
    font-weight: normal;
  }
}

// text-content-css
.text-content {
  .text-contentlink {
    border-bottom: 2px solid $green-darker;
    color: $green-darker;
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
    text-decoration: none;
  }
  span {
    font-weight: 600;
  }
}

// margin class

.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.form-group {
  select {
    &.form-control {
      min-height: 42px;
    }
  }
}

.invoice-list-head {
  .form-group {
    input {
      min-height: 42px;
    }
  }
}

// page css

.page-main-wrap {
  margin: 50px 0;

  ol,
  ul {
    padding-left: 24px;
  }

  h6 {
    font-weight: 600;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-family: $primary-font;
  }

  a {
    color: $dark-green;
  }
}
