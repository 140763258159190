/* --------------------------------------------------
  Buttons styling
-------------------------------------------------- */

.btn {
  display: inline-flex;
  height: 41px;
  min-width: 141px;
  padding: 9px 15px;
  border: none;
  border-radius: 5px;
  background-color: $green-lighter;
  background-image: none;
  color: $white;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
 /* text-transform: capitalize;*/ 
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: $dark-green;
    background-color: $dark-green;
    color: $white;
  }

  &:focus {
    box-shadow: none;
  }

  &.btn-primary {
    padding: 9px;
  }

  &.btn-arrow-right {
    padding: 9px 15px;
    flex-wrap: wrap;

    img {
      max-width: 19px;
      margin-left: 15px;
    }
  }

  &.btn-arrow-left {
    padding: 9px 15px;

    img {
      max-width: 19px;
      margin-right: 15px;
    }
  }

  &.btn-width-auto {
    height: auto;
    min-width: auto;
  }

  &.btn-outline {
    border: 2px solid $primary-green;
    background-color: transparent;
    color: $primary-green;
  }

  &.btn-blank {
    min-width: auto;
    padding: 0;
    border-color: transparent;
    background-color: transparent;
    color: $primary-green;
  }

  &.btn-dark-green {
    background-color: $dark-green;
    color: $white;
  }

  &.btn-bg-white {
    background-color: $white;
    color: $primary-green;
    &:hover {
      color: $dark-green;
    }
  }

  &.btn-color-white {
    border-color: $white;
    background-color: $primary-green;
    color: $white;

    &:hover {
      background-color: $dark-green;
    }
  }
}

.btn-left-icon {
  padding: 7px 35px 7px 39px;
  border-radius: 5px;
  color: $white;
  font-weight: 600;

  &:hover {
    color: $white;
    text-decoration: none;
  }

  img {
    height: 27px;
    width: 27px;
    margin-right: 13px;
  }

  i {
    margin-right: 8px;
  }
}

//Selectable data tables for bulk payment
.dataTables_wrapper {
  .selected {
    background-color: rgba(108, 184, 96, 0.3); 
  }

  .collection-btn {
    border: 2px solid #393a3d;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 36px;

    min-height: 36px;
    min-width: 100px;

    
    background-color: $white;
    color: $black;
    font-size: 16px;
    font-weight: 600;
  }

  #select_all, #bill_bulk_payment {
    display: inline-flex;
    height: 41px;
    min-width: 141px;
    padding: 9px 15px;
    border: none;
    border-radius: 5px;
    background-color: $green-lighter;
    background-image: none;
    color: $white;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-align: center;
  /* text-transform: capitalize;*/ 
    align-items: center;
    justify-content: center;
  }

  #select_none {
    display: inline-flex;
    height: 41px;
    min-width: 141px;
    padding: 9px 15px;
    border-radius: 5px;
    background-color: $green-lighter;
    background-image: none;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-align: center;
  /* text-transform: capitalize;*/ 
    align-items: center;
    justify-content: center;

    border: 2px solid $green-lighter;
    background-color: $white;
    color: $green-lighter;
  }
}