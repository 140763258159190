body {
  font-size: 16px;
  font-family: $primary-font;
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  color: $black-light;
}
