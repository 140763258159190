/* --------------------------------------------------
  Form styling
-------------------------------------------------- */


input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='tel'],
textarea,
select,
.form-control {
  display: inline-block;
  height: 38px;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid $light-grayer;
  color: $black-light;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 5px;

  &::placeholder {
    color: $black-light;
  }

  &:focus {
    border-color: $light-grayer;
    box-shadow: 0 0 0 .2rem rgba(234, 234, 234, 1);
  }

  &:disabled,
  &[readonly] {
    border-color: $light-grayer;
    background-color: $gray-ligher;
  }
}

.form-group {
  margin-bottom: 20px;
  label {
    margin-bottom: 7px;
    color: $light-gray;
    font-size: 14px;

    &.required,
    &.error {
      &::after {
        padding: 2px;
        color: $red;
        content: '*';
      }
    }
  }
}


.choose-file {
  .choose-file-input {
    position: absolute;
    width: 50px;
    opacity: 0;
    cursor: pointer;
  }
}
