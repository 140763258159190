/* --------------------------------------------------
 Header Style
-------------------------------------------------- */
header {
  .header-main {
    display: flex;
    padding: 15px 15px 14px;
    background-color: $green-lighter;
    box-shadow: 0 6px 9px #4242421a;
    flex-wrap: wrap;
    align-items: center;

    .logo-wrap {
      width: 150px;
      margin-right: 30px;

      img {
        max-height: 32px;
      }
    }

    .item-content-no-border,
    .item-content-with-border  {
      .item-wrap {
        display: inline-block;
        width: 100%;
        color: $white;
        font-weight: 600;
        text-decoration: none;

        img {
          display: inline-block;
          max-width: 19px;
          margin-right: 10px;
        }
      }
    }
  }

  .right-side-header {
    display: flex;
    width: calc(100% - 180px);
    padding-left: 30px;
    border-left: 4px solid $white;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .left-section {
      min-height: 32px;
      display: flex;
      align-items: center;

      @media (max-width: 575px) {
        margin-bottom: 20px;
      }

      .item-content-no-border {
        margin-left: 10px;
        display: flex;
        min-height: 36px;
        align-items: center;

        @media (max-width: 767px) {
          margin-left: 0;
        }
      }

      .item-content-with-border {
        padding-left: 40px;
        border-left: 4px solid $white;
        margin-left: 40px;

        @media (max-width: 575px) {
          padding-left: 30px;
          margin-left: 30px;
        }

        img {
          max-width: 25px;
        }
      }
    }

    .right-section {
      display: flex;
      position: relative;
      align-items: center;

      .lang-switch, .company-switch {
        position: relative;
        margin-right: 15px;

        &.open-lang-option, &.open-company-option {
          .other-lang, .other-company {
            display: block;
          }
        }
      }

      .selected-lang, .selected-company {
        color: #fff;
        font-size: 16px;
        cursor: pointer;

        i {
          margin-left: 10px;
        }
      }

      .other-lang, .other-company {
        list-style: none;
        margin: 0;
        padding: 0 16px;
        position: absolute;
        top: 38px;
        right: 0;
        z-index: 11;
        min-width: 100px;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 3px 6px #00000029;
        display: none;

        @media (max-width: 575px) {
          left: 0;
          right: auto;
        }

        li {
          color: #404040;
          list-style-type: none;

          &:not(:last-child) {
            border-bottom: 1px solid #9c9b9b;
          }

          a {
            display: block;
            padding: 12px 10px;
            color: #404040;
            text-decoration: none;
          }
        }

        &::before {
          position: absolute;
          top: -14px;
          right: 20px;
          z-index: 111;
          color: #fff;
          font-family: FontAwesome;
          content: "\F0D7";
          transform: rotate(180deg);

          @media (max-width: 575px) {
            left: 23px;
            right: auto;
          }
        }
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-top: 20px;
      padding-left: 0;
      border-left: none;
    }
  }
}

.profile-dropdown {
  .dropbtn {
    display: flex;
    border: none;
    background-color: transparent;
    color: $white;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    align-items: center;

    &:focus {
      outline: none;
    }

    img {
      margin-right: 15px;
      pointer-events: none;
    }

    i {
      margin-left: 15px;
      pointer-events: none;
    }
  }

  .name {
    display: inline-block;
    width: 100%;
    margin-bottom: 1px;
    color: $black;
    font-weight: bold;
  }

  .email {
    color: $black-light;
    font-size: 14px;
  }

  .log-outsection {
    color: $red-light;
  }

  ul {
    margin-bottom: 0;
    padding: 16px;

    li {
      border-bottom: 1px solid $light-gray;
      color: $black-light;
      list-style-type: none;

      &:last-child {
        border-bottom: none;
      }

      img {
        margin-right: 10px;
        max-width: 20px;
      }

      &:first-child {
        a {
          padding-top: 0;
        }
      }

      &:last-child {
        a {
          padding-bottom: 0;
        }
      }
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  top: 38px;
  right: 0;
  z-index: 11;
  display: none;
  min-width: 258px;
  border-radius: 5px;
  background-color: $white;
  box-shadow: 0 3px 6px #00000029;

  @media (max-width: 575px) {
    left: -40px;
    right: auto;
  }

  a {
    display: block;
    padding: 16px;
    color: $black-light;
    text-decoration: none;
  }

  &::before {
    position: absolute;
    top: -14px;
    right: 50px;
    z-index: 111;
    color: $white;
    font-family: FontAwesome;
    content: "\f0d7";
    transform: rotate(180deg);

    @media (max-width: 575px) {
      right: 72px;
    }
  }
}

.show {
  display: block;
}

header {
  .header-option-two {
    flex-wrap: unset;
    .right-side-header {
      margin-top: 0;
      .right-section {
        margin-left: auto;

        @media (max-width: 575px) {
          margin-left: a;
        }

        .lang-switch {
          margin-right: 0;
        }

        .other-lang {
          @media (max-width: 575px) {
            left: auto;
            right: 0;

            &::before {
              left: auto;
              right: 20px;
            }
          }
        }
      }
    }
  }
}
