@charset "UTF-8";
/*
_____________________________________________________

	AWS - jQuery Demo Library
_____________________________________________________

		0. Settings
    1. Functions
		1. Mixins
		3. Demo 1: Demo details;


---------- END - TABLE OF CONTENTS ----------
*/
/* --------------------------------------------------
	0.1 Settigns
-------------------------------------------------- */
/* ---- Colors ---- */
/* ---- Text ---- */
/* ---- Media Queries Breakpoints ---- */
/* --------------------------------------------------
  2.0 Mixins

-------------------------------------------------- */
body {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  color: #404040;
}

/* --------------------------------------------------
  Typography styling
-------------------------------------------------- */
body {
  font-family: "Open Sans", sans-serif;
}

a {
  color: #6cb860;
  font-size: 16px;
  line-height: 19px;
}
a:hover {
  color: #329d28;
}
a:focus {
  color: #329d28;
}
a.bold {
  font-weight: 700;
}
a.border-btm {
  border-bottom: 2px solid #6cb860;
}

p {
  color: #404040;
  font-size: 20px;
  line-height: 27px;
}

h2 {
  color: #000;
  font-size: 32px;
  line-height: 43px;
  font-weight: bold;
}

h3 {
  color: #000;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
}

.tagline {
  color: #404040;
  font-size: 20px;
  line-height: 27px;
}
.tagline span {
  font-weight: 600;
}

.title-main {
  color: #000;
  font-size: 32px;
  line-height: 43px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .title-main {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.title-main span {
  color: #404040;
  font-weight: normal;
}

.text-content .text-contentlink {
  border-bottom: 2px solid #2b9f1b;
  color: #2b9f1b;
  font-size: 16px;
  line-height: 23px;
  font-weight: 600;
  text-decoration: none;
}
.text-content span {
  font-weight: 600;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.form-group select.form-control {
  min-height: 42px;
}

.invoice-list-head .form-group input {
  min-height: 42px;
}

.page-main-wrap {
  margin: 50px 0;
}
.page-main-wrap ol,
.page-main-wrap ul {
  padding-left: 24px;
}
.page-main-wrap h6 {
  font-weight: 600;
}
.page-main-wrap p {
  font-size: 16px;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
}
.page-main-wrap a {
  color: #329d28;
}

/* --------------------------------------------------
 Header Style
-------------------------------------------------- */
header .header-main {
  display: flex;
  padding: 15px 15px 14px;
  background-color: #6cb860;
  box-shadow: 0 6px 9px #4242421a;
  flex-wrap: wrap;
  align-items: center;
}
header .header-main .logo-wrap {
  width: 150px;
  margin-right: 30px;
}
header .header-main .logo-wrap img {
  max-height: 32px;
}
header .header-main .item-content-no-border .item-wrap,
header .header-main .item-content-with-border .item-wrap {
  display: inline-block;
  width: 100%;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}
header .header-main .item-content-no-border .item-wrap img,
header .header-main .item-content-with-border .item-wrap img {
  display: inline-block;
  max-width: 19px;
  margin-right: 10px;
}
header .right-side-header {
  display: flex;
  width: calc(100% - 180px);
  padding-left: 30px;
  border-left: 4px solid #fff;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 575px) {
  header .right-side-header {
    flex-direction: column;
    align-items: flex-start;
  }
}
header .right-side-header .left-section {
  min-height: 32px;
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  header .right-side-header .left-section {
    margin-bottom: 20px;
  }
}
header .right-side-header .left-section .item-content-no-border {
  margin-left: 10px;
  display: flex;
  min-height: 36px;
  align-items: center;
}
@media (max-width: 767px) {
  header .right-side-header .left-section .item-content-no-border {
    margin-left: 0;
  }
}
header .right-side-header .left-section .item-content-with-border {
  padding-left: 40px;
  border-left: 4px solid #fff;
  margin-left: 40px;
}
@media (max-width: 575px) {
  header .right-side-header .left-section .item-content-with-border {
    padding-left: 30px;
    margin-left: 30px;
  }
}
header .right-side-header .left-section .item-content-with-border img {
  max-width: 25px;
}
header .right-side-header .right-section {
  display: flex;
  position: relative;
  align-items: center;
}
header .right-side-header .right-section .lang-switch, header .right-side-header .right-section .company-switch {
  position: relative;
  margin-right: 15px;
}
header .right-side-header .right-section .lang-switch.open-lang-option .other-lang, header .right-side-header .right-section .lang-switch.open-lang-option .other-company, header .right-side-header .right-section .lang-switch.open-company-option .other-lang, header .right-side-header .right-section .lang-switch.open-company-option .other-company, header .right-side-header .right-section .company-switch.open-lang-option .other-lang, header .right-side-header .right-section .company-switch.open-lang-option .other-company, header .right-side-header .right-section .company-switch.open-company-option .other-lang, header .right-side-header .right-section .company-switch.open-company-option .other-company {
  display: block;
}
header .right-side-header .right-section .selected-lang, header .right-side-header .right-section .selected-company {
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
header .right-side-header .right-section .selected-lang i, header .right-side-header .right-section .selected-company i {
  margin-left: 10px;
}
header .right-side-header .right-section .other-lang, header .right-side-header .right-section .other-company {
  list-style: none;
  margin: 0;
  padding: 0 16px;
  position: absolute;
  top: 38px;
  right: 0;
  z-index: 11;
  min-width: 100px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 3px 6px #00000029;
  display: none;
}
@media (max-width: 575px) {
  header .right-side-header .right-section .other-lang, header .right-side-header .right-section .other-company {
    left: 0;
    right: auto;
  }
}
header .right-side-header .right-section .other-lang li, header .right-side-header .right-section .other-company li {
  color: #404040;
  list-style-type: none;
}
header .right-side-header .right-section .other-lang li:not(:last-child), header .right-side-header .right-section .other-company li:not(:last-child) {
  border-bottom: 1px solid #9c9b9b;
}
header .right-side-header .right-section .other-lang li a, header .right-side-header .right-section .other-company li a {
  display: block;
  padding: 12px 10px;
  color: #404040;
  text-decoration: none;
}
header .right-side-header .right-section .other-lang::before, header .right-side-header .right-section .other-company::before {
  position: absolute;
  top: -14px;
  right: 20px;
  z-index: 111;
  color: #fff;
  font-family: FontAwesome;
  content: "";
  transform: rotate(180deg);
}
@media (max-width: 575px) {
  header .right-side-header .right-section .other-lang::before, header .right-side-header .right-section .other-company::before {
    left: 23px;
    right: auto;
  }
}
@media (max-width: 767px) {
  header .right-side-header {
    width: 100%;
    margin-top: 20px;
    padding-left: 0;
    border-left: none;
  }
}

.profile-dropdown .dropbtn {
  display: flex;
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
}
.profile-dropdown .dropbtn:focus {
  outline: none;
}
.profile-dropdown .dropbtn img {
  margin-right: 15px;
  pointer-events: none;
}
.profile-dropdown .dropbtn i {
  margin-left: 15px;
  pointer-events: none;
}
.profile-dropdown .name {
  display: inline-block;
  width: 100%;
  margin-bottom: 1px;
  color: #000;
  font-weight: bold;
}
.profile-dropdown .email {
  color: #404040;
  font-size: 14px;
}
.profile-dropdown .log-outsection {
  color: #f55353;
}
.profile-dropdown ul {
  margin-bottom: 0;
  padding: 16px;
}
.profile-dropdown ul li {
  border-bottom: 1px solid #9c9b9b;
  color: #404040;
  list-style-type: none;
}
.profile-dropdown ul li:last-child {
  border-bottom: none;
}
.profile-dropdown ul li img {
  margin-right: 10px;
  max-width: 20px;
}
.profile-dropdown ul li:first-child a {
  padding-top: 0;
}
.profile-dropdown ul li:last-child a {
  padding-bottom: 0;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  top: 38px;
  right: 0;
  z-index: 11;
  display: none;
  min-width: 258px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 3px 6px #00000029;
}
@media (max-width: 575px) {
  .dropdown-content {
    left: -40px;
    right: auto;
  }
}
.dropdown-content a {
  display: block;
  padding: 16px;
  color: #404040;
  text-decoration: none;
}
.dropdown-content::before {
  position: absolute;
  top: -14px;
  right: 50px;
  z-index: 111;
  color: #fff;
  font-family: FontAwesome;
  content: "";
  transform: rotate(180deg);
}
@media (max-width: 575px) {
  .dropdown-content::before {
    right: 72px;
  }
}

.show {
  display: block;
}

header .header-option-two {
  flex-wrap: unset;
}
header .header-option-two .right-side-header {
  margin-top: 0;
}
header .header-option-two .right-side-header .right-section {
  margin-left: auto;
}
@media (max-width: 575px) {
  header .header-option-two .right-side-header .right-section {
    margin-left: a;
  }
}
header .header-option-two .right-side-header .right-section .lang-switch {
  margin-right: 0;
}
@media (max-width: 575px) {
  header .header-option-two .right-side-header .right-section .other-lang {
    left: auto;
    right: 0;
  }
  header .header-option-two .right-side-header .right-section .other-lang::before {
    left: auto;
    right: 20px;
  }
}

/* --------------------------------------------------
  Footer Style
-------------------------------------------------- */
/* --------------------------------------------------
  Buttons styling
-------------------------------------------------- */
.btn, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a {
  display: inline-flex;
  height: 41px;
  min-width: 141px;
  padding: 9px 15px;
  border: none;
  border-radius: 5px;
  background-color: #6cb860;
  background-image: none;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
  /* text-transform: capitalize;*/
  align-items: center;
  justify-content: center;
}
.btn:hover, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a:hover {
  border-color: #329d28;
  background-color: #329d28;
  color: #fff;
}
.btn:focus, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a:focus {
  box-shadow: none;
}
.btn.btn-primary, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a.btn-primary {
  padding: 9px;
}
.btn.btn-arrow-right, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a.btn-arrow-right {
  padding: 9px 15px;
  flex-wrap: wrap;
}
.btn.btn-arrow-right img, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a.btn-arrow-right img {
  max-width: 19px;
  margin-left: 15px;
}
.btn.btn-arrow-left, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a.btn-arrow-left {
  padding: 9px 15px;
}
.btn.btn-arrow-left img, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a.btn-arrow-left img {
  max-width: 19px;
  margin-right: 15px;
}
.btn.btn-width-auto, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a.btn-width-auto {
  height: auto;
  min-width: auto;
}
.btn.btn-outline, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a.btn-outline {
  border: 2px solid #6cb860;
  background-color: transparent;
  color: #6cb860;
}
.btn.btn-blank, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a.btn-blank {
  min-width: auto;
  padding: 0;
  border-color: transparent;
  background-color: transparent;
  color: #6cb860;
}
.btn.btn-dark-green, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a.btn-dark-green {
  background-color: #329d28;
  color: #fff;
}
.btn.btn-bg-white, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a.btn-bg-white {
  background-color: #fff;
  color: #6cb860;
}
.btn.btn-bg-white:hover, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a.btn-bg-white:hover {
  color: #329d28;
}
.btn.btn-color-white, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a.btn-color-white {
  border-color: #fff;
  background-color: #6cb860;
  color: #fff;
}
.btn.btn-color-white:hover, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a.btn-color-white:hover {
  background-color: #329d28;
}

.btn-left-icon {
  padding: 7px 35px 7px 39px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
}
.btn-left-icon:hover {
  color: #fff;
  text-decoration: none;
}
.btn-left-icon img {
  height: 27px;
  width: 27px;
  margin-right: 13px;
}
.btn-left-icon i {
  margin-right: 8px;
}

.dataTables_wrapper .selected {
  background-color: rgba(108, 184, 96, 0.3);
}
.dataTables_wrapper .collection-btn {
  border: 2px solid #393a3d;
  cursor: pointer;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 36px;
  min-height: 36px;
  min-width: 100px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.dataTables_wrapper #select_all, .dataTables_wrapper #bill_bulk_payment {
  display: inline-flex;
  height: 41px;
  min-width: 141px;
  padding: 9px 15px;
  border: none;
  border-radius: 5px;
  background-color: #6cb860;
  background-image: none;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
  /* text-transform: capitalize;*/
  align-items: center;
  justify-content: center;
}
.dataTables_wrapper #select_none {
  display: inline-flex;
  height: 41px;
  min-width: 141px;
  padding: 9px 15px;
  border-radius: 5px;
  background-color: #6cb860;
  background-image: none;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
  /* text-transform: capitalize;*/
  align-items: center;
  justify-content: center;
  border: 2px solid #6cb860;
  background-color: #fff;
  color: #6cb860;
}

/* --------------------------------------------------
  Form styling
-------------------------------------------------- */
input[type=text],
input[type=email],
input[type=password],
input[type=number],
input[type=tel],
textarea,
select,
.form-control {
  display: inline-block;
  height: 38px;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #eaeaea;
  color: #404040;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 5px;
}
input[type=text]::placeholder,
input[type=email]::placeholder,
input[type=password]::placeholder,
input[type=number]::placeholder,
input[type=tel]::placeholder,
textarea::placeholder,
select::placeholder,
.form-control::placeholder {
  color: #404040;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=tel]:focus,
textarea:focus,
select:focus,
.form-control:focus {
  border-color: #eaeaea;
  box-shadow: 0 0 0 0.2rem #eaeaea;
}
input[type=text]:disabled, input[type=text][readonly],
input[type=email]:disabled,
input[type=email][readonly],
input[type=password]:disabled,
input[type=password][readonly],
input[type=number]:disabled,
input[type=number][readonly],
input[type=tel]:disabled,
input[type=tel][readonly],
textarea:disabled,
textarea[readonly],
select:disabled,
select[readonly],
.form-control:disabled,
.form-control[readonly] {
  border-color: #eaeaea;
  background-color: #e8e8e8;
}

.form-group {
  margin-bottom: 20px;
}
.form-group label {
  margin-bottom: 7px;
  color: #9c9b9b;
  font-size: 14px;
}
.form-group label.required::after, .form-group label.error::after {
  padding: 2px;
  color: #f00;
  content: "*";
}

.choose-file .choose-file-input {
  position: absolute;
  width: 50px;
  opacity: 0;
  cursor: pointer;
}

/* --------------------------------------------------
   Components
-------------------------------------------------- */
/* --------------------------------------------------
   Custom css
-------------------------------------------------- */
.steps-form .steps-row {
  display: flex;
  background-color: #e8e8e8;
  color: #000;
}
.steps-form .steps-row .steps-step {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
}
.steps-form .steps-row .steps-step a {
  padding: 18px 40px;
  color: #000;
  font-weight: 600;
  text-decoration: none;
}
@media (max-width: 767px) {
  .steps-form .steps-row .steps-step a {
    padding: 15px 20px;
  }
}
.steps-form .steps-row .steps-step p {
  margin-top: 0.5rem;
}
.steps-form .steps-row .steps-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.steps-form .steps-step a.active-main {
  background-color: #6cb860;
  color: #fff;
}

.form-main {
  box-shadow: 0 3px 6px #0000001a;
}
.form-main .setupcontent-inner {
  padding: 30px 40px;
}
@media (max-width: 767px) {
  .form-main .setupcontent-inner {
    padding: 15px;
  }
}
.form-main .setupcontent-inner .stepcontent {
  max-width: 850px;
}
.form-main .setupcontent-inner .stepcontent .tooltip-inner {
  background-color: #6cb860;
}
.form-main .setupcontent-inner .stepcontent .tooltip {
  margin-left: 15px;
}
.form-main .setupcontent-inner .stepcontent .tooltip.fade.in {
  opacity: 1;
}
.form-main .setupcontent-inner .stepcontent .tooltip.fade.right.in {
  opacity: 1;
}
.form-main .setupcontent-inner .stepcontent .tooltip.fade.right.in a.active-main {
  color: #fff;
}
.form-main .setupcontent-inner .stepcontent .tooltip.fade.right.in::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #6cb860 transparent transparent;
}
@media (max-width: 767px) {
  .form-main .setupcontent-inner .stepcontent .tooltip {
    left: 15px !important;
  }
  .form-main .setupcontent-inner .stepcontent .tooltip.fade.top.in {
    opacity: 1;
  }
  .form-main .setupcontent-inner .stepcontent .tooltip.fade.top.in::after {
    content: " ";
    position: absolute;
    bottom: -10px;
    left: 45%;
    margin-top: 0;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #6cb860 transparent transparent;
    transform: rotate(270deg);
  }
}
.form-main .setupcontent-inner .stepcontent a[data-toggle=tooltip] {
  color: #b9b9b9;
}
.form-main .setupcontent-inner .stepcontent .payment-choice label {
  margin: 0;
}
.form-main .setupcontent-inner .stepcontent .payment-choice label.error {
  margin-left: -24px;
  margin-top: 5px;
}
.form-main .setupcontent-inner .stepcontent .payment-choice input {
  width: 20px;
  height: 20px;
  margin: 0 0 0 10px;
}
.form-main .setupcontent-inner .stepcontent .payment-choice .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6cb860;
}
.form-main .setupcontent-inner .stepcontent .payment-choice .eft-check {
  display: flex;
  flex-wrap: wrap;
}
.form-main .setupcontent-inner .stepcontent .payment-choice .eft-check .error {
  width: 100%;
  order: 3;
}
.form-main .button-footer {
  padding: 18px 40px 8px;
  background-color: #f5f5f5;
}
@media (max-width: 767px) {
  .form-main .button-footer {
    padding: 15px 15px 7px;
  }
}
.form-main .button-footer button {
  margin-right: 40px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .form-main .button-footer button {
    margin-right: 10px;
  }
}
.form-main .button-footer button.saveBtn:hover {
  background-color: #6cb860;
  color: #fff;
}

.body-main {
  padding: 40px 40px 60px;
}
@media (max-width: 767px) {
  .body-main {
    padding: 15px;
  }
}
.body-main .text-content {
  margin-bottom: 40px;
}

.company-logo-content {
  display: flex;
  align-items: center;
}
.company-logo-content .logo-wrap {
  display: flex;
  height: 77px;
  margin-right: 20px;
  border: 2px solid #eaeaea;
  border-radius: 5px;
  flex-wrap: wrap;
  align-items: center;
}
.company-logo-content .logo-wrap img {
  max-height: 70px;
  height: auto;
  width: 182px;
  object-fit: contain;
}
.company-logo-content .link-content-edit {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #6cb860;
  font-weight: 600;
}
.company-logo-content .link-content-edit i {
  margin-right: 6px;
}

.homepage-background {
  display: flex;
  height: calc(100vh - 136px);
  width: 100%;
  padding: 20px;
  background-image: url("../assets/images/homelightbg.png");
  background-repeat: no-repeat;
  background-size: 98% 100%;
  align-items: center;
}
@media (max-width: 767px) {
  .homepage-background {
    height: calc(100vh);
  }
}
@media (max-width: 1199px) {
  .homepage-background {
    background-size: cover;
  }
}
.homepage-background .toast-component {
  max-width: 296px;
}
@media (max-width: 767px) {
  .homepage-background .toast-component {
    max-width: 296px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
  }
}

.top-header-main {
  background-color: #329d28;
  padding: 13px;
  min-height: 68px;
}
.top-header-main .content-wrap {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .top-header-main .content-wrap {
    flex-direction: column;
    justify-content: left;
  }
}
.top-header-main .content-wrap .link-logo img {
  display: inline;
}
.top-header-main .content-wrap .link-navigation {
  display: inline-block;
  width: 90%;
  text-align: right;
}
@media (max-width: 767px) {
  .top-header-main .content-wrap .link-navigation {
    width: 100%;
    text-align: center;
  }
}
.top-header-main .content-wrap .link-navigation a {
  color: #fff;
  font-weight: bold;
  display: inline-block;
}

.bottom-footer-main {
  background-color: #329d28;
  padding: 13px;
  min-height: 68px;
}
.bottom-footer-main .content-wrap {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .bottom-footer-main .content-wrap {
    flex-direction: column;
    justify-content: center;
  }
}
.bottom-footer-main .content-wrap .copyright-text {
  display: inline-block;
  width: 55%;
  color: #fff;
  text-align: right;
  font-weight: bold;
}
@media (max-width: 767px) {
  .bottom-footer-main .content-wrap .copyright-text {
    width: 100%;
    text-align: center;
  }
}
.bottom-footer-main .content-wrap .link-navigation {
  display: inline-block;
  width: 45%;
  text-align: right;
}
@media (max-width: 767px) {
  .bottom-footer-main .content-wrap .link-navigation {
    width: 100%;
    text-align: center;
  }
}
.bottom-footer-main .content-wrap .link-navigation a {
  color: #fff;
  font-weight: bold;
  display: inline-block;
}

.social-media-login {
  position: relative;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 24px;
}
.social-media-login span {
  background-color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  display: inline-block;
  line-height: 24px;
  position: relative;
  z-index: 1;
  color: #747487;
}

.social-media-login:before {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 12px;
  background-color: #ededf4;
}

.social-media-buttons {
  padding-top: 30px;
  position: relative;
}
.social-media-buttons button {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-decoration: none;
  text-align: center;
  padding: 10px;
  outline: none;
}
.social-media-buttons button span {
  width: 48px;
  height: 48px;
  padding: 13px;
  display: block !important;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(82, 82, 128, 0.09);
  border-radius: 16px;
  color: #232333;
}
.social-media-buttons button:hover {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-decoration: none;
  text-align: center;
  padding: 10px;
}
.social-media-buttons button:hover span {
  width: 48px;
  height: 48px;
  padding: 13px;
  display: block !important;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(82, 82, 128, 0.09);
  border-radius: 16px;
  background-color: #f0f0f0;
  color: #232333;
}
.social-media-buttons button:active span {
  width: 48px;
  height: 48px;
  padding: 13px;
  display: block !important;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(82, 82, 128, 0.5);
  border-radius: 16px;
  background-color: #e6e6e6;
  color: #232333;
}

.pageNumbers {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.head-content-page {
  margin-bottom: 50px;
}

.page-main-wrap .border-line-first {
  padding-left: 24px;
}
.page-main-wrap .line-border-form .border-line li {
  border-bottom: 1px solid #000;
  list-style-type: none;
  display: inline-block;
  width: 50%;
  padding: 10px 0;
}

.homepage-main {
  display: flex;
  max-width: 960px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 17px;
  background-color: #fff;
  box-shadow: 3px 9px 28px #0000000d;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .homepage-main {
    display: inline-block;
    width: 100%;
    background-color: transparent;
  }
}
@media (max-width: 767px) {
  .homepage-main .left-section {
    width: 100% !important;
    text-align: center;
  }
}
.homepage-main .left-section img {
  display: inline-block;
  width: 100%;
}
@media (max-width: 767px) {
  .homepage-main .left-section img {
    max-width: 300px;
  }
}
.homepage-main .right-section {
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}
.homepage-main .right-section .homebtnwrap img {
  max-height: 50px;
}
.homepage-main .right-section .title-main {
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .homepage-main .right-section {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 767px) {
  .homepage-main .right-section {
    width: 100% !important;
    padding-left: 0;
    text-align: center;
  }
}

.homepage-main .left-section,
.homepage-main .right-section {
  width: 50%;
}

.quickbook-page {
  padding: 15px;
}
.quickbook-page .text-content {
  margin-bottom: 56px;
}
.quickbook-page .text-content .title-main {
  margin-bottom: 13px;
}
.quickbook-page .quickbook-btnwrap .btn-left-icon {
  padding: 7px 18px;
}

.page-mainwrap {
  display: flex;
  height: 100vh;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.page-mainwrap.with-header {
  height: calc(100vh - 65px);
}
@media (max-width: 767px) {
  .page-mainwrap.with-header {
    height: calc(100vh - 117px);
  }
}

.item-steps {
  padding: 0 40px 60px;
  border-top: 6px solid #6cb860;
  background-color: #fff;
}
@media (max-width: 767px) {
  .item-steps {
    padding: 0 15px 30px;
  }
}
.item-steps .company-logo-main {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #eaeaea;
}
.item-steps .company-logo-main img {
  max-height: 106px;
}
.item-steps .company-logo-main .company-main-title {
  font-weight: bold;
  color: #000;
}
.item-steps .title-wrap {
  margin-top: 40px;
  margin-bottom: 40px;
}
.item-steps .item-in-wrap {
  border-top: 12px solid #6cb860;
  box-shadow: 0 3px 6px #0000001a;
}
.item-steps .item-in-wrap .item-group {
  max-width: 780px;
  padding: 40px 40px 60px;
}
@media (max-width: 767px) {
  .item-steps .item-in-wrap .item-group {
    max-width: 100%;
    padding: 20px 10px 40px;
  }
}
.item-steps .item-in-wrap .form-groupwrap .form-group:last-child {
  margin-bottom: 30px;
}
.item-steps .item-in-wrap .form-groupwrap label.error, .item-steps .item-in-wrap .form-groupwrap .label-error {
  color: #f00;
}
.item-steps .item-in-wrap .pay-cardsection img,
.item-steps .item-in-wrap .cardsection img {
  max-width: 20px;
  margin-right: 14px;
}
.item-steps .item-in-wrap .pay-cardsection .pay-title,
.item-steps .item-in-wrap .cardsection .pay-title {
  display: flex;
  padding: 15px 20px;
  border-radius: 5px 5px 0 0;
  background-color: #6cb860;
  color: #fff;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
}
@media (max-width: 767px) {
  .item-steps .item-in-wrap .pay-cardsection .pay-title,
.item-steps .item-in-wrap .cardsection .pay-title {
    padding: 15px 10px;
  }
}
.item-steps .item-in-wrap .pay-cardsection .pay-title .pay-title-wrap,
.item-steps .item-in-wrap .cardsection .pay-title .pay-title-wrap {
  display: flex;
  align-items: center;
}
.item-steps .item-in-wrap .pay-cardsection .pay-title .pay-title-wrap .img-wrap,
.item-steps .item-in-wrap .cardsection .pay-title .pay-title-wrap .img-wrap {
  display: flex;
  align-items: center;
}
.item-steps .item-in-wrap .pay-cardsection .pay-title .pat-bambora-logo,
.item-steps .item-in-wrap .cardsection .pay-title .pat-bambora-logo {
  margin-left: 10px;
}
.item-steps .item-in-wrap .pay-cardsection .pay-title .pat-bambora-logo img,
.item-steps .item-in-wrap .cardsection .pay-title .pat-bambora-logo img {
  max-width: 60px;
  margin-right: 0;
}
.item-steps .item-in-wrap .pay-cardsection .pay-card-inputs,
.item-steps .item-in-wrap .pay-cardsection .card-inputs,
.item-steps .item-in-wrap .cardsection .pay-card-inputs,
.item-steps .item-in-wrap .cardsection .card-inputs {
  padding: 20px;
  border: 2px solid #eaeaea;
  border-top: none;
  border-radius: 5px;
}
@media (max-width: 767px) {
  .item-steps .item-in-wrap .pay-cardsection .pay-card-inputs,
.item-steps .item-in-wrap .pay-cardsection .card-inputs,
.item-steps .item-in-wrap .cardsection .pay-card-inputs,
.item-steps .item-in-wrap .cardsection .card-inputs {
    padding: 10px;
  }
}
.item-steps .item-in-wrap .button-footer {
  padding: 18px 40px;
  background-color: #f5f5f5;
}
@media (max-width: 767px) {
  .item-steps .item-in-wrap .button-footer {
    padding: 18px 10px;
  }
}

.combine-input {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
.combine-input .form-group {
  float: left;
  display: inline-block;
  width: calc(50% - 9px);
  margin-right: 18px;
}
@media (max-width: 767px) {
  .combine-input .form-group {
    width: 100%;
    margin-right: 0;
  }
}
.combine-input .form-group:last-child {
  margin-right: 0;
}

.custom-message-wrap {
  display: flex;
  margin: 40px 40px 0;
  padding-bottom: 40px;
  border-bottom: 1px solid #eaeaea;
  align-items: center;
}
@media (max-width: 767px) {
  .custom-message-wrap {
    margin: 0;
    padding: 15px;
    flex-direction: column;
  }
}
.custom-message-wrap .message-image-wrap {
  margin-right: 30px;
}
@media (max-width: 767px) {
  .custom-message-wrap .message-image-wrap {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.custom-message-wrap .message-image-wrap img {
  max-width: 78px;
}
@media (max-width: 767px) {
  .custom-message-wrap .message-image-wrap img {
    max-width: 40px;
  }
}
@media (max-width: 767px) {
  .custom-message-wrap .message-content-wrap {
    width: 100%;
  }
}
.custom-message-wrap h2 {
  margin-bottom: 4px;
}
.custom-message-wrap p {
  margin-bottom: 0;
}

.payment-summary .pay-card-inputs,
.payment-summary .card-inputs,
.summary .pay-card-inputs,
.summary .card-inputs {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.payment-summary .pay-card-inputs ul,
.payment-summary .card-inputs ul,
.summary .pay-card-inputs ul,
.summary .card-inputs ul {
  margin-bottom: 0;
  padding-left: 0;
}
.payment-summary .pay-card-inputs ul li,
.payment-summary .card-inputs ul li,
.summary .pay-card-inputs ul li,
.summary .card-inputs ul li {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid #eaeaea;
  list-style-type: none;
}
.payment-summary .pay-card-inputs ul li:last-child,
.payment-summary .card-inputs ul li:last-child,
.summary .pay-card-inputs ul li:last-child,
.summary .card-inputs ul li:last-child {
  border-bottom: none;
}
.payment-summary .pay-card-inputs ul li .title-content,
.payment-summary .pay-card-inputs ul li .value-content,
.payment-summary .card-inputs ul li .title-content,
.payment-summary .card-inputs ul li .value-content,
.summary .pay-card-inputs ul li .title-content,
.summary .pay-card-inputs ul li .value-content,
.summary .card-inputs ul li .title-content,
.summary .card-inputs ul li .value-content {
  display: inline-block;
  color: #404040;
  font-size: 14px;
}
.payment-summary .pay-card-inputs ul li .value-content,
.payment-summary .card-inputs ul li .value-content,
.summary .pay-card-inputs ul li .value-content,
.summary .card-inputs ul li .value-content {
  color: #000;
  font-weight: bold;
}

.company-profile {
  margin-bottom: 40px;
  box-shadow: 0 1px 15px #0000001a;
}
.company-profile img {
  max-height: 107px;
  padding: 10px 40px;
}

.item-detail .item-group {
  max-width: 100% !important;
  padding-top: 0 !important;
}
.item-detail .item-group .item-details-list {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 0;
  border-bottom: 2px solid #eaeaea;
}
.item-detail .item-group .item-details-list li {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  padding-right: 50px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  list-style-type: none;
}
@media (max-width: 991px) {
  .item-detail .item-group .item-details-list li {
    width: 100%;
    padding-left: 30px;
  }
}
.item-detail .item-group .item-details-list li::before {
  position: absolute;
  top: 11px;
  left: -32px;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #dedede;
  content: "";
}
@media (max-width: 991px) {
  .item-detail .item-group .item-details-list li::before {
    top: 10px;
    left: 5px;
  }
}
@media (max-width: 767px) {
  .item-detail .item-group .item-details-list li {
    width: 100%;
  }
}
.item-detail .item-group .item-details-list li:first-child::before {
  display: none;
}
@media (max-width: 991px) {
  .item-detail .item-group .item-details-list li:first-child::before {
    display: block;
  }
}
.item-detail .item-group .item-details-list li:last-child {
  padding-right: 0;
}
.item-detail .item-group .item-details-list li .true {
  color: #6cb860;
}
.item-detail .item-group .item-details-list li .false {
  color: #f55353;
}

.preview-page .item-group {
  max-width: 100% !important;
}
.preview-page .item-group .card-inputs .title-content {
  min-width: 200px;
}
@media (max-width: 767px) {
  .preview-page .item-group .card-inputs .title-content {
    min-width: auto;
    width: 100%;
  }
}
.preview-page .item-group .card-inputs .value-content {
  word-break: break-all;
}
.preview-page .item-group .card-inputs .value-content.company-logo-img img {
  max-height: 70px;
  max-width: 100%;
}

.li-structure ul {
  max-width: 271px;
  margin-left: auto;
  padding-left: 0;
  border-top: none;
  border-bottom: none;
}
.li-structure ul li {
  padding: 20px;
  border-right: 2px solid #eaeaea;
  border-bottom: 2px solid #eaeaea;
  border-left: 2px solid #eaeaea;
  list-style-type: none;
}
@media (max-width: 767px) {
  .li-structure ul li {
    padding: 10px;
  }
}
.li-structure ul li .title-content,
.li-structure ul li .value-content {
  display: inline-block;
  color: #404040;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
}
.li-structure ul li .value-content {
  color: #000;
  font-weight: bold;
}
.li-structure ul li.btn-fullwidth {
  padding: 0;
  border: none;
}
.li-structure ul li.btn-fullwidth a {
  display: flex;
  height: 48px;
  width: 100%;
  border-radius: 0;
  align-items: center;
  justify-content: center;
}
.li-structure ul li.balance-output {
  border-color: #f5f5f5;
  background-color: #f5f5f5;
}

@media (max-width: 767px) {
  .calculation-table {
    margin-top: 20px;
  }
  .calculation-table .li-structure ul {
    max-width: 100%;
  }
  .calculation-table .li-structure ul li:first-child {
    border-top: 2px solid #eaeaea;
  }
  .calculation-table .li-structure ul li .title-content {
    width: 120px;
  }
}

.table-wrap {
  width: 100%;
  overflow-y: auto;
}
.table-wrap .item-detail-table-main {
  width: 95%;
  table-layout: fixed;
}
.table-wrap .item-detail-table-main .item-detail-table th {
  padding: 35px 10px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 0.36px;
}
.table-wrap .item-detail-table-main .item-detail-table th:nth-child(4) {
  width: 136px;
}
.table-wrap .item-detail-table-main .item-detail-table th:nth-child(5) {
  width: 136px;
}
@media (max-width: 767px) {
  .table-wrap .item-detail-table-main .item-detail-table th {
    min-width: 150px;
    padding: 10px;
  }
}
.table-wrap .item-detail-table-main .item-detail-table tr {
  border-bottom: 2px solid #eaeaea;
}
.table-wrap .item-detail-table-main .item-detail-table td {
  padding: 20px 15px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: 0.32px;
}
@media (max-width: 767px) {
  .table-wrap .item-detail-table-main .item-detail-table td {
    min-width: 150px;
    padding: 10px;
  }
}

.mobile-view-table {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .mobile-view-table {
    display: none;
  }
}
@media (max-width: 991px) {
  .mobile-view-table.list {
    display: inline-block;
    width: 100%;
  }
}
.mobile-view-table .table-card {
  margin-bottom: 20px;
}
.mobile-view-table .table-card:last-child {
  margin-bottom: 0;
}
.mobile-view-table .table-card .li-structure ul {
  max-width: 100%;
  width: 100%;
  margin-bottom: 0;
  border: 2px solid #eaeaea;
}
.mobile-view-table .table-card .li-structure ul li {
  padding: 10px;
  border-right: none;
  border-left: none;
}
.mobile-view-table .table-card .li-structure ul li .title-content {
  width: 120px;
}

@media (max-width: 767px) {
  .desktop-view {
    display: none;
  }
}

.item-list-head {
  margin-bottom: 40px;
  padding: 30px 30px 20px;
  background-color: #6cb860;
  box-shadow: 0 3px 6px #0000001a;
}
.item-list-head .item-list-head-btn {
  display: flex;
  flex-wrap: wrap;
}
.item-list-head .item-list-head-btn .btn, .item-list-head .item-list-head-btn .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last .item-list-head .item-list-head-btn a {
  margin-right: 25px;
}
@media (max-width: 480px) {
  .item-list-head .item-list-head-btn .btn, .item-list-head .item-list-head-btn .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a, .dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last .item-list-head .item-list-head-btn a {
    margin-bottom: 20px;
  }
}
.item-list-head .combine-input .form-group {
  width: calc(50% - 15px);
  margin-right: 30px;
}
@media (max-width: 767px) {
  .item-list-head .combine-input .form-group {
    width: 100%;
    margin-right: 0;
  }
}
.item-list-head .combine-input .form-group:last-child {
  margin-right: 0;
}

.item-list .item-in-wrap .item-group {
  max-width: 100%;
  padding-top: 0;
}
.item-list .item-in-wrap .item-group .item-detail-table thead {
  border-bottom: 2px solid #eaeaea;
}
.item-list .item-in-wrap .item-group .item-detail-table tr {
  border-bottom: none;
}
.item-list .item-in-wrap .item-group .item-detail-table tr td {
  padding: 15px 10px;
}
.item-list .item-in-wrap .item-group .item-detail-table tr td .btn-outline {
  height: 37px;
  min-width: 122px;
}
.item-list .item-in-wrap .item-group .item-detail-table tr td .btn-outline i {
  margin-right: 10px;
  font-size: 20px;
}
.item-list .item-in-wrap .item-group .item-detail-table tr td .btn-outline:hover {
  color: #329d28;
}
.item-list .item-in-wrap .item-group .item-detail-table tr td .btn-width-auto {
  min-width: 67px;
}
.item-list .item-in-wrap .item-group .item-detail-table th:nth-child(4) {
  width: auto;
}
.item-list .item-in-wrap .item-group .item-detail-table th:nth-child(5) {
  width: auto;
}
@media (min-width: 992px) {
  .item-list .item-in-wrap .item-group .mobile-view-table.list {
    display: none;
  }
}
@media (min-width: 992px) {
  .item-list .item-in-wrap .item-group .mobile-view-table {
    display: none;
  }
}
.item-list .item-in-wrap .item-group .mobile-view-table .btn-outline:hover {
  color: #329d28;
}
@media (max-width: 991px) {
  .item-list .item-in-wrap .item-group .desktop-view-list {
    display: none;
  }
}

.iframe-input-pay {
  padding: 40px 20px 20px !important;
}
.iframe-input-pay input,
.iframe-input-pay label,
.iframe-input-pay iframe {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
}
.iframe-input-pay label.error, .iframe-input-pay .label-error {
  color: #f00;
}

#invoiceList_wrapper {
  margin: 30px 0;
}
@media (max-width: 767px) {
  #invoiceList_wrapper .row {
    flex-direction: column;
  }
  #invoiceList_wrapper .row #invoiceList_paginate {
    display: flex;
    justify-content: flex-start;
    margin: 0;
  }
}
@media (max-width: 767px) {
  #invoiceList_wrapper #invoiceList_length {
    margin-bottom: 20px;
  }
}
#invoiceList_wrapper #invoiceList_length label {
  display: flex;
  align-items: center;
}
#invoiceList_wrapper #invoiceList_length label .custom-select {
  height: 41px;
  width: 80px;
  margin-left: 5px;
  margin-right: 10px;
}

#invoiceList_filter label,
#billList_filter label,
#customerList_filter label {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  #invoiceList_filter label,
#billList_filter label,
#customerList_filter label {
    justify-content: flex-start;
  }
}
#invoiceList_filter label input,
#billList_filter label input,
#customerList_filter label input {
  margin-left: 10px;
  margin-right: 5px;
  height: 41px;
}

#invoiceList_info {
  font-weight: 600;
  padding: 15px;
  margin-top: 30px;
  margin-bottom: 15px;
}

#invoiceList_paginate,
#billList_paginate {
  margin-top: 30px;
  margin-bottom: 15px;
}
#invoiceList_paginate .pagination,
#billList_paginate .pagination {
  justify-content: flex-end;
  padding: 15px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  #invoiceList_paginate .pagination,
#billList_paginate .pagination {
    padding: 0 15px;
  }
}
#invoiceList_paginate .pagination li a,
#billList_paginate .pagination li a {
  color: #329d28;
  font-weight: 600;
}
#invoiceList_paginate .pagination .paginate_button .page-link,
#billList_paginate .pagination .paginate_button .page-link {
  color: #329d28;
}
#invoiceList_paginate .pagination .paginate_button.active .page-link,
#billList_paginate .pagination .paginate_button.active .page-link {
  background-color: #329d28;
  border-color: #329d28;
  color: #fff;
}

.dataTables_processing {
  text-align: center;
}
.dataTables_processing #loaders img {
  max-height: 105px;
}

#card-number,
#card-cvv,
#card-expiry {
  background-color: #fff;
  display: block;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin: auto;
  transition: all 100ms ease-out;
}
#card-number:focus,
#card-cvv:focus,
#card-expiry:focus {
  border-color: #eaeaea;
  box-shadow: 0 0 0 0.2rem #eaeaea;
}

.step-content-2 .label-error,
.item-pay-section .label-error {
  margin-bottom: 0;
}
.step-content-2 .label-error:empty,
.item-pay-section .label-error:empty {
  display: none;
}
.step-content-2 .error,
.item-pay-section .error {
  margin-bottom: 0;
}
.step-content-2 .iframe-input-pay .form-group,
.item-pay-section .iframe-input-pay .form-group {
  margin-bottom: 20px;
}
.step-content-2 .form-control:focus,
.item-pay-section .form-control:focus {
  box-shadow: none;
  border: 1px solid #c8c8c8;
}
.step-content-2 .form-control::placeholder,
.item-pay-section .form-control::placeholder {
  color: #9c9b9b;
  font-weight: 400;
  font-size: 13.3333px;
  font-family: arial;
}
.step-content-2 .form-control:-ms-input-placeholder,
.item-pay-section .form-control:-ms-input-placeholder {
  color: #9c9b9b;
  font-weight: 400;
  font-size: 13.3333px;
  font-family: arial;
}
.step-content-2 .form-control::-ms-input-placeholder,
.item-pay-section .form-control::-ms-input-placeholder {
  color: #9c9b9b;
  font-weight: 400;
  font-size: 13.3333px;
  font-family: arial;
}
.step-content-2 .pay-card-inputs.iframe-input-pay,
.item-pay-section .pay-card-inputs.iframe-input-pay {
  padding-bottom: 20px !important;
}

/* card images are added to card number */
#card-number {
  background-image: none;
  background-origin: content-box;
  background-position: calc(100% + 40px) center;
  background-repeat: no-repeat;
  background-size: contain;
}

button#pay-button.disabled {
  background-color: #585858;
}

/* feedback is displayed after tokenization */
#feedback {
  position: relative;
  left: 15px;
  display: inline-block;
  background-color: transparent;
  border: 0px solid #c8c8c8;
  border-radius: 4px;
  transition: all 100ms ease-out;
  padding: 11px;
}

#feedback.error {
  color: #f00;
  border: 1px solid;
}

#feedback.success {
  color: #329d28;
  border: 1px solid;
}

/*// css for payment form end*/
.admin-login {
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.admin-login .admin-inner-wrap {
  display: inline-block;
  width: 100%;
  max-width: 800px;
}
.admin-login .admin-inner-wrap .item-steps {
  border-top: none;
  padding: 0;
}
.admin-login .admin-inner-wrap .form-main {
  padding: 40px 30px;
}
@media (max-width: 767px) {
  .admin-login .admin-inner-wrap .form-main {
    padding: 40px 0;
  }
}
.admin-login .admin-inner-wrap .main-title {
  margin-bottom: 30px;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
  margin-top: 20px;
  /*display: inline-block;*/
  display: inline-block;
  width: 100%;
}
.dataTables_wrapper .dataTables_length label,
.dataTables_wrapper .dataTables_filter label {
  display: inline-flex;
  align-items: center;
}
.dataTables_wrapper .dataTables_length select.form-control {
  margin-left: 10px;
  margin-right: 10px;
  height: auto;
  min-width: 70px;
}
.dataTables_wrapper .dataTables_filter {
  text-align: right;
}
@media (max-width: 767px) {
  .dataTables_wrapper .dataTables_filter {
    text-align: left;
  }
}
.dataTables_wrapper .dataTables_filter label {
  width: 100%;
  max-width: 500px;
}
.dataTables_wrapper .dataTables_filter label .form-control {
  margin-left: 10px;
  margin-right: 10px;
}
.dataTables_wrapper .row:last-child {
  background-color: #f5f5f5;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
}
.dataTables_wrapper .row:last-child .dataTables_info {
  font-weight: 600;
  letter-spacing: 0.32px;
}
.dataTables_wrapper .row:last-child .dataTables_paginate {
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .dataTables_wrapper .row:last-child .dataTables_paginate {
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.dataTables_wrapper .row:last-child .dataTables_paginate .pagination {
  justify-content: flex-end;
  padding: 15px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .dataTables_wrapper .row:last-child .dataTables_paginate .pagination {
    padding: 0 15px;
    justify-content: flex-start;
  }
}
.dataTables_wrapper .row:last-child .dataTables_paginate .pagination li a {
  color: #329d28;
  font-weight: 600;
  background-color: transparent;
  border-color: transparent;
}
.dataTables_wrapper .row:last-child .dataTables_paginate .pagination .paginate_button .page-link {
  color: #404040;
}
.dataTables_wrapper .row:last-child .dataTables_paginate .pagination .paginate_button.active .page-link {
  color: #6cb860;
}
.dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last {
  margin-left: 54px;
}
.dataTables_wrapper .row:last-child .dataTables_paginate .pagination .last a {
  width: auto;
  color: #fff !important;
  min-width: auto;
}

.failed-transaction-section .combine-input {
  display: flex;
}
@media (max-width: 991px) {
  .failed-transaction-section .combine-input {
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .failed-transaction-section .combine-input .form-group:nth-child(2) {
    margin-right: 0;
  }
}

.iframe-input-pay input[type=text],
.iframe-input-pay input[type=email],
.iframe-input-pay input[type=password],
.iframe-input-pay input[type=number],
.iframe-input-pay input[type=tel],
.iframe-input-pay textarea,
.iframe-input-pay select,
.iframe-input-pay .form-control {
  color: #000;
  border: 1px solid #eaeaea !important;
  font: 400 13.3333px Arial;
  color: #000;
  background-color: #fff !important;
}
.iframe-input-pay input[type=text]::placeholder,
.iframe-input-pay input[type=email]::placeholder,
.iframe-input-pay input[type=password]::placeholder,
.iframe-input-pay input[type=number]::placeholder,
.iframe-input-pay input[type=tel]::placeholder,
.iframe-input-pay textarea::placeholder,
.iframe-input-pay select::placeholder,
.iframe-input-pay .form-control::placeholder {
  color: #757575;
}
.iframe-input-pay label.label-error {
  margin-bottom: 0;
}
.iframe-input-pay label.label-error:empty {
  display: none;
}
.iframe-input-pay .saved-info-section {
  color: #757575;
  display: flex;
  align-items: center;
  font: 400 13.3333px Arial;
  margin-bottom: 20px;
}
.iframe-input-pay .saved-info-section input {
  margin-right: 5px;
}

.modal.fade {
  padding: 15px;
  opacity: 0;
  transition: opacity 0.15s linear;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal.fade.in {
  opacity: 1;
}

.modal.in .modal-dialog {
  transform: translate(0, 0);
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out, -o-transform 0.3s ease-out;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
    position: relative;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}
.modal-dialog .modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
}
@media (min-width: 768px) {
  .modal-dialog .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}
.modal-dialog .modal-content .modal-header {
  padding: 15px;
  border-bottom: 1px solid #329d28;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background-color: #6cb860;
  color: #fff;
}
.modal-dialog .modal-content .modal-header .modal-title {
  font-weight: bold;
}
.modal-dialog .modal-content .modal-header .close {
  cursor: pointer;
  color: #fff;
  opacity: 1;
}
.modal-dialog .modal-content .modal-body .content-modal-body {
  display: flex;
  margin-bottom: 10px;
}
.modal-dialog .modal-content .modal-body label {
  font-weight: bold;
  min-width: 160px;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .modal-dialog .modal-content .modal-body label {
    min-width: 140px;
  }
}
@media (max-width: 575px) {
  .modal-dialog .modal-content .modal-body label,
.modal-dialog .modal-content .modal-body span {
    font-size: 14px;
  }
}

.modal.fade .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.center_label {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.dt-body-right, .dt-head-right {
  text-align: right;
}

.dt-body-center, .dt-head-center {
  text-align: center;
}

.dt-body-left, .dt-head-left {
  text-align: left;
}

.remove-border-top {
  border-top: none !important;
}

.loading-spinner {
  display: none;
}

.loading-spinner.active {
  display: inline-block;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #6cb860;
  border-color: #ffffff;
}

.form-group .error, .form-group .label-error {
  color: #f00;
}

.item-steps {
  padding: 0 40px 60px;
  border-top: 6px solid #6cb860;
  background-color: #fff;
}
@media (max-width: 767px) {
  .item-steps {
    padding: 0 15px 30px;
  }
}
.item-steps .company-logo-main {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #eaeaea;
}
.item-steps .company-logo-main img {
  max-height: 106px;
}
.item-steps .company-logo-main .company-main-title {
  font-weight: bold;
  color: #000;
}
.item-steps .title-wrap {
  margin-top: 40px;
  margin-bottom: 40px;
}

.breadcrumb-navigation {
  padding: 0 40px 0px;
  border-top: 6px solid #6cb860;
  background-color: #fff;
}
@media (max-width: 767px) {
  .breadcrumb-navigation {
    padding: 0 15px 30px;
  }
}
.breadcrumb-navigation .breadcrumb {
  background-color: #fff;
  padding: 0.75rem 1rem 0rem 1rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 60px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #6CB860;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6CB860;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}